// mutationTypes
import {
  SET_MOVED_RECORD,
  SET_MOVED_RECORDS,
  UPDATE_DOCUMENT_LIST_BY_ID,
  UPDATE_RECORD_INFO
} from "@/store/shared/mutationTypes/record/record-mutation-types";

// vuex
import { createNamespacedHelpers } from "vuex";
const {
  mapState,
  mapGetters,
  mapActions,
  mapMutations
} = createNamespacedHelpers("agenda");

// mixins
import { dataTableSearchableFolderDocumentsMixin } from "@/mixins/shared/documents/dataTableSearchableFolderDocumentsMixin";
import { moduleRecordNavigatable } from "@/mixins/shared/navigatable/moduleRecordNavigatable";

/**
 * dataTableAgendaDocumentsMixin encapsulate agenda module dataTable Documents
 */
export const dataTableAgendaDocumentsMixin = {
  mixins: [dataTableSearchableFolderDocumentsMixin, moduleRecordNavigatable],
  computed: {
    ...mapState({
      /**
       * Selected record
       * Overwrite dataTableDocumentsMixin.record()
       * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[] }}
       */
      record: "record",

      /**
       * documents of selected category
       * Overwrite dataTableDocumentsMixin.items()
       * @return {{id:number, name:string, categoryId:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, flags:number, hasImage:boolean, owner:string, pages:number, recordTypeId:number, recordType:string, version:number, versionDate:string}[]}
       */
      items: "documents",

      /**
       * Selected document
       * Overwrite dataTableDocumentsMixin.currentDocument()
       * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, owner:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, version: number, versionDate: string, versionOwner: string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: any}[], flyingFields: {id:number, sequence:number}[]}}
       */
      currentDocument: "document",

      /**
       * current selected Document Category
       * remarks: Overwrite Abstract computed dataTableDocumentsMixin.currentDocumentCategory
       * @return {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[]}
       */
      currentDocumentCategory: "documentCategory",

      /**
       * selected File VersionF
       */
      selectedFileVersion: "version",

      /**
       * selected File Version Category
       * remarks: Overwrite computed dataTableDocumentsMixin.selectedFileVersionCategory
       * @return {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[]}
       */
      selectedFileVersionCategory: "versionCategory",

      /**
       * Get search Category
       * Overwrite dataTableSearchableDocumentsMixin.searchCategory()
       * @return {*|{id: number, name: string, formId: number, flags: number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags: number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[]|(function(): {id: number, name: string, formId: number, flags: number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags: number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[])}
       */
      searchCategory: "searchCategory",

      /**
       * Fetch Document List Id
       */
      fetchDocumentListById: "fetchDocumentListById"
    }),

    ...mapGetters({
      /**
       * Overwrite dataTableMixin.selectedModuleItemId()
       */
      selectedModuleItemId: "selectedModuleItemId",

      /**
       * Overwrite dataTableMixin.selectedModuleItemName()
       */
      selectedModuleItemName: "selectedModuleItemName"
    })
  },

  methods: {
    ...mapActions({
      /**
       * Overwrite base methods
       */
      setDocuments: "setDocuments",
      setChildrenDocuments: "setChildrenDocuments",
      setDocument: "setDocument",
      downloadFile: "downloadFile",
      setFoundDocuments: "setFoundDocuments",
      setSearchCategory: "setSearchCategory",
      clearDocuments: "clearDocuments",
      setFileVersion: "setFileVersion",
      setDocumentChildren: "setDocumentChildren",
      getTotalCountWithin: "getTotalCountWithin",
      checkOutFile: "checkOutFile",
      deleteRecord: "deleteRecord",
      deleteRecordShortcut: "deleteRecordShortcut",
      restoreRecord: "restoreRecord",
      lockRecord: "lockRecord",
      unlockRecord: "unLockRecord",
      setReadOnlyRecord: "setReadOnlyRecord",
      submitAgendaItem: "submitAgendaItem",
      recallAgendaItem: "recallAgendaItem"
    }),
    ...mapMutations({
      updateRecordInfo: UPDATE_RECORD_INFO,
      setMovedRecord: SET_MOVED_RECORD,
      setMovedRecords: SET_MOVED_RECORDS,
      updateDocumentListById: UPDATE_DOCUMENT_LIST_BY_ID
    })
  }
};
